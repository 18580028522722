import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useBingo } from './../../context/BingoContext';

const CustomPlayer = () => {
  const { anuncios } = useBingo();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Define a função para avançar para o próximo anúncio
    const nextAnuncio = () => {
      setCurrentIndex((prevIndex) => {
        // Verifica se há mais anúncios disponíveis
        if (prevIndex + 1 < anuncios.length) {
          // Avança para o próximo anúncio
          return prevIndex + 1;
        } else {
          // Se não houver mais anúncios, volta para o primeiro
          return 0;
        }
      });
    };

    // Define o tempo de exibição do anúncio atual
    const currentAnuncio = anuncios[currentIndex];
    const tempo = currentAnuncio ? currentAnuncio.tempo * 1000 : 0; // Tempo em milissegundos
    const timer = setTimeout(nextAnuncio, tempo);

    // Limpa o temporizador quando o componente é desmontado ou quando o próximo anúncio é exibido
    return () => clearTimeout(timer);
  }, [anuncios, currentIndex]);

  const renderAnuncio = (anuncio) => {
    if (anuncio && anuncio.tipo === 'video') {
      return (
        <ReactPlayer
          url={anuncio.url}
          controls={false}
          playing={true}
          muted={false} 
          width='80vw'
          height='100vh'
          fullscreen={true} 
        />
      );
    } else if (anuncio && anuncio.tipo === 'banner') {
      return (
        // <img src={anuncio.url} alt="Banner" style={{ }} />
        <img src={anuncio.url} alt="Banner" style={{ 
           width:'80vw',
          height:'100vh'}} />
      );
    }
  };

  if (anuncios && anuncios.length > 0) {
    return (
      <div>
        {renderAnuncio(anuncios[currentIndex])}
      </div>
    );
  } else {
    return null;
  }
}

export default CustomPlayer;