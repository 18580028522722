import React from 'react';

import { useBingo } from './../context/BingoContext';
import images from '../utils/imageLoader'; 


const UltimasBolas = () => {
  const { numerosSorteados } = useBingo();
 
  const ultimasBolas = numerosSorteados.slice(-8);

  return (
    <>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    {/* Linha superior */}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        {ultimasBolas.slice(0, 4).map((numero, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${images[`${numero}.png`]})`,
              backgroundSize: 'cover',
            //   width: '9vw',
            //   height: '14vh',
            // //   border: '1px solid #ccc'
            // marginBottom: 10,

            width: '20%', // Ajuste a largura como porcentagem para ser responsiva
            height: 'auto', // Altura será ajustada proporcionalmente
            aspectRatio: '1', // Garante que o contêiner seja quadrado
            // border: '1px solid #ccc',
            marginBottom: '7px',
            }}
          />
        ))}
      </div>
     {/* Linha inferior */}
     <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        {ultimasBolas.slice(4).map((numero, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${images[`${numero}.png`]})`,
              backgroundSize: 'cover',
              width: '20%', // Ajuste a largura como porcentagem para ser responsiva
              height: 'auto', // Altura será ajustada proporcionalmente
              aspectRatio: '1', // Garante que o contêiner seja quadrado
            //   border: '1px solid #ccc',
              marginBottom: '7px',
            }}
          />
        ))}
      </div>
      </div>

    </>
  )
}
  
  export default UltimasBolas;