// Sorteio.js
import React, { useEffect, useState } from 'react';
import { useBingo } from './../context/BingoContext.js';
import io from 'socket.io-client'; 
import BolasSorteadas from '../components/BolasSorteadas';
import PremioSorteio from '../components/PremioSorteio';
import RankingUser from '../components/Ranking';
import SaiuPremio from '../components/SaiuPremio.js';
import UltimasBolas from '../components/UltimasBolas.js';
import ComponentBall from '../components/animacao/ComponentBall';
import backgroundImageContainer from './../images/backgroundPremios.png';
import coutImageBoll from './../images/bls/1.png';
import CustomPlayer from './../components/anuncios/anuncios';
import ProximaRodada from '../components/anuncios/proximaRodada.js';

const SOCKET_URL = `${process.env.REACT_APP_API_SOCKET_URL}`;

const Sorteio = () => {
  const { message,premios, updateBingoData, numerosSorteados, anuncios, setDes_quadra, setDes_quina,setDes_keno,setDes_acumulado } = useBingo();
  const [quantidadeNumeros, setQuantidadeNumeros] = useState(0);
  const [anunciosExp, setAnunciosExp] = useState(false);
  const [isHidden, setIsHidden] = useState(true); // true == oculta!!
  const [countdown, setCountdown] = useState(10);
  const [premiosShow, setPremiosShow] = useState(true);

  useEffect(() => {
    if (message === 'saiuPremio')  {
      setIsHidden(true); // Mostra os elementos
      setPremiosShow(true);
    } else if (message === 'contagem')  {
      setIsHidden(true); // Mostra os elementos
      setDes_quadra(false);
      setDes_quina(false);
      setDes_keno(false);
      setDes_acumulado(false);
      setCountdown(10);
      const timer = setInterval(() => {
        setCountdown(prev => {
          if (prev === 0) {
            setIsHidden(false); // Mostra os elementos
            clearInterval(timer); // Para a contagem
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      setIsHidden(false); // Mostra os elementos se já houver números sorteados
      setPremiosShow(false);
    }
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    setQuantidadeNumeros(numerosSorteados.length);
    if(numerosSorteados.length > premios[5]){
      setDes_acumulado(true);
    }
    setTimeout(() => {}, 500);
    // eslint-disable-next-line
  }, [numerosSorteados]);

  useEffect(() => {
    if (message === 'anuncioStart') {
      setTimeout(() => {
        setAnunciosExp(true);
      }, 2000);
    } else {
      setAnunciosExp(false);
    }
  }, [message, anuncios]);

  useEffect(() => {
    const socket = io(SOCKET_URL, {
        transports: ['websocket'],
        withCredentials: true,      
    });

    socket.on('connect', () => {
        // console.log('Conexão WebSocket bem-sucedida! Conectado ao servidor WebSocket em:', SOCKET_URL);
      });
      
      socket.on('error', (error) => {
        console.error('Erro de conexão WebSocket:', error);
    });

    socket.on('AtualizarDados', (data) => {
      updateBingoData({
        premioAtual: data.premioAtual,
        start: data.start,
        message: data.message,
        numerosSorteados: data.numerosSorteados,
        rankingDez: data.rankingDez,
        premios: data.premios,
        primeiroPremio: data.primeiroPremio,
        segundoPremio: data.segundoPremio,
        terceiroPremio: data.terceiroPremio,
        anuncios: data.anuncios,
        proximaRodada: data.proximaRodada,
        premio_intervalo: data.premio_intervalo,
        bola_intervalo: data.bola_intervalo,
        primeiro_intervalo: data.primeiro_intervalo
      });
    });

    return () => {
      socket.off('AtualizarDados'); 
      socket.off('error');  
    };
  }, [updateBingoData]);
  
  return (
    <div>
      <div style={{
        // position: 'absolute', 
        display: 'flex' ,
        top: 0, right: '0vw', width: '100vw', height: '100vh', 
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImageContainer})`,
        margin: '0 auto',
         borderRadius: '0px', 
         overflow: 'hidden',
         backgroundColor: 'transparent'
      }}>
        {numerosSorteados.length === 0 && countdown > 0 && message === 'contagem' && (
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ffontSize: 'clamp(5rem, 7vw, 3rem)',
            color: 'white'
          }}>
            <h1>{countdown}</h1>
          </div>
        )}

        {premiosShow && (
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '5rem',
            color: 'white'
          }}>
            <SaiuPremio />
          </div>
        )}

        {!isHidden && (
          <div>
            {anunciosExp && anuncios &&
              <div style={{ display: 'flex' }}>
                <div style={{ flex: '1',backgroundColor: 'lightblue', height: '100vh', width: '80vw' }}>
                  <CustomPlayer />
                </div>
                <div style={{  backgroundColor: '#000', height: '100vh', width: '20vw' }}>
                  <ProximaRodada />
                </div>
              </div>
            }
            {anunciosExp === false &&
              <>
                <div style={{ width: '30vw', height: '100vh', position: 'absolute', top: 0, left: 0 }}>
                  <RankingUser />
                </div>
                <div style={{
                  width: '57vw', height: '30vh', position: 'absolute', right: '0', top: 0,
                  marginRight: '13vw'
                }}>
                  <PremioSorteio />
                </div>
                <div style={{
                  position: 'absolute',
                  right: 0, width: '18vw', height: '18vw',
                  backgroundSize: 'cover', margin: '0 auto', borderRadius: '0px', overflow: 'hidden'
                }}>
                  <div style={{
                    position: 'absolute', zIndex: 1,
                    backgroundImage: `url(${coutImageBoll})`, backgroundSize: 'cover',
                    width: '70%', height: '70%',
                    top: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'
                  }}>
                    <div style={{
                      backgroundColor: 'white', borderRadius: '50%',
                      display: 'flex', justifyContent: 'center', alignItems: 'center',
                      width: '60%', height: '60%'
                    }}>
                      <h1 style={{ margin: 0, fontSize: '6vw' }}>{quantidadeNumeros}</h1>
                    </div>
                  </div>
                </div>

                <div style={{
                  position: 'absolute',
                  width: '36vw', height: '70vh',
                  marginLeft: '30vw', marginTop: '30vh',
                  backgroundSize: 'cover', borderRadius: '0px',
                }}>
                  <ComponentBall />
                </div>

                <div style={{
                  width: '33vw', height: '40vh', backgroundColor: 'white', position: 'absolute', right: 10,
                  bottom: '30vh', display: 'flex', justifyContent: 'flex-end'
                }}>
                  <BolasSorteadas />
                </div>

                <div style={{
                  position: 'absolute', bottom: 1, right: 1, width: '34vw', height: '30vh',
                  marginTop: '10px'
                }}>
                  <UltimasBolas />
                </div>
              </>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default Sorteio;

