// src/utils/imageLoader.js
function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    const key = item.replace('./', '');
    images[key] = r(item); 
  });
  return images;
}

// Import all images from the directory
const images = importAll(require.context('../images/bls', false, /\.(png|jpe?g|svg)$/));

export default images;
