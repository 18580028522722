import React from 'react';
import './PremioSorteio.css'; 
import { useBingo } from './../context/BingoContext';
import formatarParaMonetario from '../utils/formatMonetario';

const PremioSorteio = () => {
    const { premios, des_quadra, des_quina, des_keno, des_acumulado } = useBingo();

    return (
        <>
            <div className='premio-container'>
                <div 
                    className={`premio-item ${des_quadra ? 'disabled' : ''}`}
                    style={{ backgroundColor: des_quadra ? 'grey' : 'green' }}
                >
                    <h2 className='text' style={{  fontSize: 'clamp(1rem, 2vw, 3rem)',}}>
                        KUADRA {premios.length > 0 ? formatarParaMonetario(premios[0]) : formatarParaMonetario(0)}
                    </h2>
                    <div className='bg'></div>
                    <div className='blob'></div>
                </div>

                {/* KINA */}
                <div 
                    className={`premio-item ${des_quina ? 'disabled' : ''}`}
                    style={{ backgroundColor: des_quina ? 'grey' : 'green' }}
                >
                    <h2 className='text' style={{  fontSize: 'clamp(1rem, 2vw, 3rem)',}}>
                        KINA {premios.length > 0 ? formatarParaMonetario(premios[1]) : formatarParaMonetario(0)}
                    </h2>
                    <div className='bg'></div>
                    <div className='blob'></div>
                </div>

                {/* KENO */}
                <div 
                    className={`premio-item ${des_keno ? 'disabled' : ''}`}
                    style={{ backgroundColor: des_keno ? 'grey' : 'green' }}
                >
                    <h2 className='text' style={{  fontSize: 'clamp(1rem, 2vw, 3rem)',}}>
                        KENO {premios.length > 0 ? formatarParaMonetario(premios[2]) : formatarParaMonetario(0)}
                    </h2>
                    <div className='bg'></div>
                    <div className='blob'></div>
                </div>

                {/* ACUMULADO */}
                <div 
                    className={`premio-item ${des_acumulado ? 'disabled' : ''}`}
                    style={{ backgroundColor: des_acumulado ? 'grey' : 'green' }}
                >
                    <h2 className='text' style={{  fontSize: 'clamp(1rem, 2vw, 3rem)',}}>
                        ACUMULADO {premios.length > 0 ? formatarParaMonetario(premios[3]) : formatarParaMonetario(0)}
                    </h2>
                    <div className='bg'></div>
                    <div className='blob'></div>
                </div>
            </div>

            <div className='containerTop'>
                <div className='rodada'>
                    <h1 className='text1' style={{  fontSize: 'clamp(1rem, 2vw, 3rem)',}}>RODADA: {premios.length > 0 ? premios[4] : 0}</h1>
                </div>
                <div className='doacao'>
                    <h1 className='text1' style={{  fontSize: 'clamp(1rem, 2vw, 3rem)',}}>DOAÇÃO: {premios.length > 0 ? formatarParaMonetario(premios[6]) : formatarParaMonetario(0)}</h1>
                </div>
                <div className='acumulado'>
                    <h1 className='text1' style={{  fontSize: 'clamp(1rem, 2vw, 3rem)',}}>ACUMULADO {premios.length > 0 ? premios[5] : "..."}</h1>
                </div>
            </div>
        </>
    );
}

export default PremioSorteio;
