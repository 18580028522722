import React, { createContext, useContext, useState } from 'react';

const BingoContext = createContext();

export const useBingo = () => useContext(BingoContext);

export const BingoProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [premioAtual, setPremioAtual] = useState('');
  const [numerosSorteados, setNumerosSorteados] = useState([]);
  const [rankingDez, setRankingDez] = useState([]);
  const [premios, setPremios] = useState([]);
  const [primeiroPremio, setPrimeiroPremio] = useState(   
//       [
//     {
//         "linha1": [
//             7,
//             15,
//             50
//         ],
//         "linha2": [
//             12
//         ],
//         "linha3": [
//             80,
//             37
//         ],
//         "cartela": "[67, 7, 15, 50, 89, 20, 12, 72, 52, 38, 80, 37, 81, 11, 65]",
//         "falta": [
//             7,
//             15,
//             50,
//             12,
//             80,
//             37
//         ],
//         "id": 2649,
//         "acertos": 0,
//         "tipo": 0,
//         "id_user": 1,
//         "nome": "bar do thyagp",
//         "tipo_user": "cambista",
//         "linha1c": [
//             67,
//             7,
//             15,
//             50,
//             89
//         ],
//         "linha2c": [
//             20,
//             12,
//             72,
//             52,
//             38
//         ],
//         "linha3c": [
//             80,
//             37,
//             81,
//             11,
//             65
//         ],
//         "codigo": 64349,
//         "pago": 10
//     }
// ]
);
  const [segundoPremio, setSegundoPremio] = useState([]);
  const [terceiroPremio, setTerceiroPremio] = useState([]);
  const [anuncios, setAnuncios] = useState([]);
  const [start, setStart] = useState(false);
  const [premio_intervalo, setPremio_intervalo] = useState(false);
  const [bola_intervalo, setBola_intervalo] = useState(false);
  const [primeiro_intervalo, setPrimeiro_intervalo] = useState(false);
  const [dados_proxima_rodada, setDados_proxima_rodada] = useState([]);
  const [des_quadra, setDes_quadra] = useState(false);
  const [des_quina, setDes_quina] = useState(false);
  const [des_keno, setDes_keno] = useState(false);
  const [des_acumulado, setDes_acumulado] = useState(false);

  const updateBingoData = (data) => {
    // console.log(data)
    setStart(data.start);
    setMessage(data.message);
    setPremioAtual(data.premioAtual);
    setNumerosSorteados(data.numerosSorteados);
    setRankingDez(data.rankingDez);
    setPremios(data.premios);
    setPrimeiroPremio(data.primeiroPremio);
    setSegundoPremio(data.segundoPremio);
    setTerceiroPremio(data.terceiroPremio);
    setAnuncios(data.anuncios);
    setDados_proxima_rodada(data.proximaRodada);
    setPremio_intervalo(data.premio_intervalo);
    setBola_intervalo(data.bola_intervalo);
    setPrimeiro_intervalo(data.primeiro_interval);

  };
  return (
    <BingoContext.Provider value={{ start, message, numerosSorteados, rankingDez,premios, updateBingoData,primeiroPremio, segundoPremio, terceiroPremio, anuncios, premioAtual,
      premio_intervalo, bola_intervalo, primeiro_intervalo, dados_proxima_rodada, setDes_quadra, setDes_quina,setDes_keno,
      setDes_acumulado,des_quadra,des_quina,des_keno, des_acumulado
    }}>
      {children}
    </BingoContext.Provider>
  );
};
