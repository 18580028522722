import React, { useRef, useEffect, useState } from 'react';
import { useBingo } from './../../context/BingoContext';

const BallVersion = () => {
  const { numerosSorteados } = useBingo();
  const [bola, setBola] = useState(0);
  const canvasRef = useRef(null);

  const playAudio = () => {
    setTimeout(() => {
      const audio = new Audio(`/assents/audio/${bola}.mp3`);
      audio.play().then(() => {
        // console.log(`Áudio ${bola}.mp3 reproduzido com sucesso.`);
      }).catch(error => {
        // console.error(`Erro ao reproduzir o áudio ${bola}.mp3:`, error);
      });
    }, 1000);
  }

  useEffect(() => {
    playAudio();
    // eslint-disable-next-line
  }, [bola]);

  useEffect(() => {
    // Pegar o último número sorteado
    const ultimoNumeroSorteado = numerosSorteados[numerosSorteados.length - 1];
    // Verificar se o último número existe e é um número válido
    if (ultimoNumeroSorteado && !isNaN(ultimoNumeroSorteado)) {
      setBola(ultimoNumeroSorteado);
    }
  }, [numerosSorteados]);

  const width = 650;
  const height = 350;
  const ball = {
    position: { x: width / 2, y: 0 },
    // position: { x: 0, y: 0 },
    velocity: { x: 10, y: 0 },
    mass: 1.9,
    radius: 95,
    restitution: -1.7
  };
  const Cd = 0.47;
  const rho = 1.22;
  const A = Math.PI * ball.radius * ball.radius / (10000);
  const ag = 9.81;
  const frameRate = 1 / 40;
  const frameDelay = frameRate * 1000;
  let loopTimer;

  const cores = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff'];

  const escolherCorAleatoria = () => {
    return cores[Math.floor(Math.random() * cores.length)];
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    // ctx.fillStyle = 'red';
    ctx.fillStyle = escolherCorAleatoria();
    ctx.strokeStyle = '#000000';

    const setup = () => {
      // eslint-disable-next-line
      loopTimer = setInterval(loop, frameDelay);
    };

    const loop = () => {
      let Fx = -0.5 * Cd * A * rho * ball.velocity.x * ball.velocity.x * ball.velocity.x / Math.abs(ball.velocity.x);
      let Fy = -0.5 * Cd * A * rho * ball.velocity.y * ball.velocity.y * ball.velocity.y / Math.abs(ball.velocity.y);

      Fx = (isNaN(Fx) ? 0 : Fx);
      Fy = (isNaN(Fy) ? 0 : Fy);

      const ax = Fx / ball.mass;
      const ay = ag + (Fy / ball.mass);

      ball.velocity.x += ax * frameRate;
      ball.velocity.y += ay * frameRate;

      ball.position.x += ball.velocity.x * frameRate * 100;
      ball.position.y += ball.velocity.y * frameRate * 100;


      if (ball.position.y > height - ball.radius) {
        ball.velocity.y *= ball.restitution;
        ball.position.y = height - ball.radius;
      }


      if (ball.position.y < ball.radius) {
        ball.velocity.y *= ball.restitution;
        ball.position.y = ball.radius;
      }

      if (ball.position.x > width - ball.radius) {
        ball.velocity.x *= ball.restitution;
        ball.position.x = width - ball.radius;
      }

      if (ball.position.x < ball.radius) {
        ball.velocity.x *= ball.restitution;
        ball.position.x = ball.radius;
      }

      ctx.clearRect(0, 0, width, height);
      ctx.save();

      // Desenhar a bola com os estilos CSS desejados
      ctx.fillStyle = '#fff';
      ctx.beginPath();
      ctx.arc(ball.position.x, ball.position.y, ball.radius, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
      ctx.lineWidth = 55; // Defina a largura da borda
      ctx.strokeStyle = '#330066'; // Defina a cor da borda
      // ctx.fillStyle = escolherCorAleatoria();

      ctx.stroke();

      // Adicionar o número no centro da bola
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      ctx.font = 'bold ' + ball.radius + 'px serif';
      ctx.fillText(bola, ball.position.x, ball.position.y + ball.radius / 3);

      // ctx.translate(ball.position.x, ball.position.y);
      // ctx.beginPath();
      // ctx.arc(0, 0, ball.radius, 0, Math.PI * 2, true);
      // ctx.closePath();
      // ctx.fill();
      // ctx.fillStyle = 'white';
      // ctx.textAlign = 'center';
      // ctx.font = 'bold ' + ball.radius + 'px serif';
      // ctx.fillText(bola, 0, 30);
      // ctx.restore();
    };

    setup();

    return () => clearInterval(loopTimer);
  }, [bola]);

  return (
    <div style={{ display: 'flex', height: '100%', flexWrap: 'wrap', gap: '1px', padding: '1px', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        <canvas ref={canvasRef} width={width} height={height} style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </div>
    </div>
  )
}

export default BallVersion;
// 407