import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Sorteio from './pages/sorteio';
import { BingoProvider } from './context/BingoContext';
import PageNotFound from './pages/PageNotFound';

const App = () => {
   
    return (
        <BingoProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Sorteio />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </BrowserRouter>
        </BingoProvider>
    );
}

export default App;
