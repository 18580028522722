import React, { useEffect, useState } from 'react';
import { useBingo } from '../context/BingoContext';
import './SaiuPremio.css';
import Confetti from 'react-confetti';

import formatarParaMonetario from '../utils/formatMonetario';

const PremioBox = ({ premio, numerosSorteados, bola }) => (
    <div className="premioBox">
        <h1 className="nomePremio">{premio.codigo} - {premio.nome}</h1>
        {[premio.linha1c, premio.linha2c, premio.linha3c].map((linha, linhaIndex) => (
            <div key={linhaIndex} className="numeroLinha">
                {linha.map((numero, numeroIndex) => (
                    <div
                        key={`${linhaIndex}-${numeroIndex}`}
                        className={`numero ${numero === bola ? 'red' : ''} ${numerosSorteados.includes(numero) ? 'green' : ''}`}
                    >
                        <h3 style={{  fontSize: 'clamp(1rem, 3vw, 4rem)',}}>{numero < 10 ? `0${numero}` : numero}</h3>
                    </div>
                ))}
            </div>
        ))}
        <h1 className="valorPremio">{premio.pago > 0 ? formatarParaMonetario(premio.pago) : formatarParaMonetario(0)}</h1>
    </div>
);

const SaiuPremio = () => {
    const { numerosSorteados, premio_intervalo, primeiroPremio, segundoPremio, terceiroPremio, premioAtual,  setDes_quadra, setDes_quina,setDes_keno } = useBingo();
    
    const [bola, setBola] = useState(0);
    const [mostrarPremio1, setMostrarPremio1] = useState(false);
    const [mostrarPremio2, setMostrarPremio2] = useState(false);
    const [mostrarPremio3, setMostrarPremio3] = useState(false);

    useEffect(() => {
        if (premioAtual === 'primeiro') {
            setDes_quadra(true);
            setMostrarPremio1(true);
            const audio = new Audio(`/assents/audio/quadra.mp3`);
            audio.play().catch((error) => {
                console.error(`Erro ao reproduzir o áudio quadra.mp3:`, error);
            });
            setTimeout(() => {
                setMostrarPremio1(false); // Oculta o prêmio após o intervalo
            }, parseInt(premio_intervalo));
        }
        if (premioAtual === 'segundo') {
            setDes_quina(true);
            setMostrarPremio2(true);
            const audio = new Audio(`/assents/audio/quina.mp3`);
            audio.play().catch((error) => {
                console.error(`Erro ao reproduzir o áudio quina.mp3:`, error);
            });
            setTimeout(() => {
                setMostrarPremio2(false); // Oculta o prêmio após o intervalo
            }, parseInt(premio_intervalo));
        }
        if (premioAtual === 'terceiro') {
            setMostrarPremio3(true);
            setDes_keno(true);
            const audio = new Audio(`/assents/audio/keno.mp3`);
            audio.play().catch((error) => {
                console.error(`Erro ao reproduzir o áudio keno.mp3:`, error);
            });
            setTimeout(() => {
                setMostrarPremio3(false); // Oculta o prêmio após o intervalo
            }, parseInt(premio_intervalo));
        }
        // eslint-disable-next-line
    }, [premioAtual]);

    useEffect(() => {
        const ultimoNumeroSorteado = numerosSorteados[numerosSorteados.length - 1];
        if (ultimoNumeroSorteado && !isNaN(ultimoNumeroSorteado)) {
            setBola(ultimoNumeroSorteado);
        }
    }, [numerosSorteados]);

    return (
        <div className="containerPremio1">
                 <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={200}
                gravity={0.5}
                size={165}
                colors={['#ff0000', '#00ff00', '#0000ff']}
            />
            {mostrarPremio1 && primeiroPremio.map((premio, index) => (
                <PremioBox key={index} premio={premio} numerosSorteados={numerosSorteados} bola={bola} />
            ))}
            {mostrarPremio2 && segundoPremio.map((premio, index) => (
                <PremioBox key={index} premio={premio} numerosSorteados={numerosSorteados} bola={bola} />
            ))}
            {mostrarPremio3 && terceiroPremio.map((premio, index) => (
                <PremioBox key={index} premio={premio} numerosSorteados={numerosSorteados} bola={bola} />
            ))}
        </div>
    );
};
export default SaiuPremio;