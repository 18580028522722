import React, { useState } from 'react';
import './Ranking.css';
import { useBingo } from './../context/BingoContext';

const CatListItem = ({ cat, rank }) => {
  // eslint-disable-next-line
  const { rankingDez } = useBingo();

  const colors = ["#d6cd1e", "#bbbbbb", "#d6a21e"];
  // eslint-disable-next-line
  const [colorOrder] = useState(colors[rank]);


  return (
    <li className="cat-item">
      <h4 style={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
        <div
          style={{
            flex: "1 0 46%",
            marginRight: "1%",
            overflow: "hidden", 
            whiteSpace: "nowrap", 
            textOverflow: "ellipsis",
            maxWidth: "100%", 
          }}
        >
          <span style={{ fontSize: 'clamp(1rem, 1.4vw, 1.5rem)', }}>
            {cat.codigo}-{cat.nome}
          </span>
        </div>
        <div style={{ flex: "1 0 45%" }}>
          <div className="bordered-numbers">
            {cat.falta.slice(0, 7).map((numero, index) => (
              <span
                key={index}
                style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'clamp(1rem, 1.4vw, 1.5rem)', // Ajuste o tamanho da bola
                  height: 'clamp(1rem, 1.4vw, 1.5rem)', // Altura igual à largura para garantir que seja um círculo
                  borderRadius: '50%', // Faz a borda ser circular
                  // backgroundColor: 'white', // Cor de fundo
                  border: '2px solid black', // Adiciona uma borda
                  margin: '3px', // Espaçamento entre as bolas
                  fontSize: '11px', // Ajusta o tamanho do texto
                  textAlign: 'center',
                }}
              >
                {numero < 10 ? `0${numero}` : numero}
              </span>
            ))}
          </div>

        </div>
      </h4>
    </li>
  );
};

const Ranking = () => {
  // eslint-disable-next-line
  const catRank = [
    { r: 2, c: "#d6a21e" },
    { r: 0, c: "#d6cd1e" },
    { r: 1, c: "#bbbbbb" }
  ];
  const { rankingDez, setRankingDez } = useBingo();

  // eslint-disable-next-line
  const handlePointIncrement = (index) => {
    const updatedCats = [...rankingDez];
    updatedCats[index].points++;
    setRankingDez(updatedCats);
  };

  // eslint-disable-next-line
  const handlePointDecrement = (index) => {
    const updatedCats = [...rankingDez];
    updatedCats[index].points--;
    setRankingDez(updatedCats);
  };

  const allCats = rankingDez.slice().sort((a, b) => b.points - a.points);
  // eslint-disable-next-line
  const topThreeCats = allCats.slice(0, 3).map((cat, index) => ({ ...cat, index }));

  return (
    <div className="container">
      <div className="board">
        <ul>
          {allCats.map((cat, index) => (
            <CatListItem key={index} cat={cat} rank={index} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Ranking;
//115