import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
import { useBingo } from './../../context/BingoContext';
import './proximaRodada.css';

const ProximaRodada = () => {
  const { anuncios, dados_proxima_rodada } = useBingo();
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    // Define a função para avançar para o próximo anúncio
    const nextAnuncio = () => {
      setCurrentIndex((prevIndex) => {
        // Verifica se há mais anúncios disponíveis
        if (prevIndex + 1 < anuncios.length) {
          // Avança para o próximo anúncio
          return prevIndex + 1;
        } else {
          // Se não houver mais anúncios, volta para o primeiro
          return 0;
        }
      });
    };

    // Define o tempo de exibição do anúncio atual
    const currentAnuncio = anuncios[currentIndex];
    const tempo = currentAnuncio ? currentAnuncio.tempo * 1000 : 0; // Tempo em milissegundos
    const timer = setTimeout(nextAnuncio, tempo);
    return () => clearTimeout(timer);
  }, [anuncios, currentIndex]);

  const [remainingTime, setRemainingTime] = useState('');


  // Função para calcular o tempo restante até 14:00
  const calculateRemainingTime = () => {
    if(dados_proxima_rodada[5]){
    const currentTime = new Date();
    const targetTime = new Date(dados_proxima_rodada[5]);
    const hours1 = targetTime.getUTCHours(); // Horas (0-23)
    const minutes1 = targetTime.getMinutes(); // Minutos (0-59)
    const seconds1 = targetTime.getSeconds(); // Segundos (0-59)
    targetTime.setHours(hours1);
    targetTime.setMinutes(minutes1);
    targetTime.setSeconds(seconds1);

    let difference = targetTime.getTime() - currentTime.getTime();

    // Verificar se já passou das 14:00
    if (difference < 0) {
      difference += 24 * 60 * 60 * 1000; // Adiciona um dia se o horário já passou
    }

    const hours = Math.floor(difference / (60 * 60 * 1000));
    const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);
    // Formatar o tempo restante
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    setRemainingTime(formattedTime);
  }else{
    setRemainingTime('Sem Rodadas');
  }

  };

  // Atualizar o tempo restante a cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ width: '20vw', height: '100%' }} >
      <div style={{ width: '100%', height: '100%' }} >
        {/* <head>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap" rel="stylesheet" />
        </head> */}

        <div className="header1"><strong style={{ fontSize: '2.7vw' }} >Proxima rodada</strong></div>
        <div className="card1">
          <div className="wrapper">
            <div className="display">

              <div id="time" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                textAlign: 'center',
                fontSize: remainingTime !== 'Sem Rodadas' ? 'clamp(2rem, 1vw, 3rem)' : 'clamp(1rem, 1vw, 3rem)' ,
              }}
              >{remainingTime}</div>
            </div>
            <span></span>
            <span></span>
          </div>

        </div>

        <div className="card1">
          <div className="item1">
            <center>
              <strong style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>DOAÇÕES</strong>
              <p style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>{dados_proxima_rodada[6] ? dados_proxima_rodada[6].toFixed(2)  : '0.00'}</p>
              {/* <p><span style={{ lineHeight: '14px' }}><img src="https://i.imgur.com/00gUeNo.png" alt="Complete" /> Complete</span></p> */}
            </center>
          </div>
        </div>

        <div className="card2">
          <div className="row">
            <div className="item">
              <center>
                <strong style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>Kuadra</strong>
                <p style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>{dados_proxima_rodada[1] ? dados_proxima_rodada[1].toFixed(2)  : '0.00'}</p>
              </center>
            </div>
            <div className="item">
              <center>
                <strong style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>Kina</strong>
                <p style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>{ dados_proxima_rodada[2] ? dados_proxima_rodada[2].toFixed(2)  : '0.00'}</p>
              </center>
            </div>
          </div>
          <div className="row">
            <div className="item">
              <center>
                <strong style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>Keno</strong>
                <p style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>{dados_proxima_rodada[3] ? dados_proxima_rodada[3].toFixed(2)  : '0.00'}</p>
              </center>
            </div>
            <div className="item">
              <center>
                <strong style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>Acumulado</strong>
                <p style={{ fontSize: 'clamp(1rem, 2vw, 3rem)', }}>{dados_proxima_rodada[4] ? dados_proxima_rodada[4].toFixed(2) : '0.00'}</p>
              </center>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default ProximaRodada;
